<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      default: ''
    },
    slug: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: '#'
    },
    images: {
      type: Object,
      default: null
    },
    background_color: {
      type: String,
      default: ''
    },
    col: {
      type: Number,
      default: null
    },
    row: {
      type: Number,
      default: null
    },
    index: {
      type: Number,
      default: 0
    },
    noBorder: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<template>
  <a
    :href="`/kampanj/${slug}`"
    class="campaign-box span-2-cols"
    :class="{
      'colored-background': images.double_column === null,
      'no-border': noBorder
    }"
    :style="{
      gridColumn: col ? col + ' / span 2' : 'span 2',
      gridRow: row ? row : '',
      backgroundColor: background_color ? background_color : 'transparent'
    }"
  >
    <img
      v-if="images.double_column !== null"
      loading="lazy"
      :srcset="`${
        images.double_column_high_res !== null ? images.double_column_high_res.url + ' 2x' : ''
      }`"
      :src="images.double_column.url"
      :alt="images.double_column.alt"
    />

    <div class="content">
      <div>
        <h2>{{ name }}</h2>
        <p>{{ description }}</p>
        <span v-if="images.double_column === null" class="read-more">Gå till kampanjen -></span>
      </div>
    </div>
  </a>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

// Very rough base for the "Tips & råd" boxes
.campaign-box {
  position: relative;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  overflow: hidden;
  min-height: 550px;
  &.no-border {
    border-width: 0px;
  }
  @media (max-width: 1299px) {
    grid-column: span 2 !important; // Get rid of the important
  }
  @media (max-width: 1023px) {
    grid-column: span 2 !important; // Get rid of the important
    min-height: 450px;
  }
  &:hover img {
    transform: scale(1.04);
  }
  img {
    transition: transform 1s ease-in-out;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left center;
    display: block;
    position: absolute;
  }
  .content {
    padding: 2rem;
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &::after {
      content: '';
      height: 70%;
      width: 100%;
      position: absolute;
      background: linear-gradient(0deg, black, transparent);
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
  h2 {
    color: #fff;
    font-family: $brandon;
    font-weight: 700;
    font-size: 2.5rem;
    margin: 0 0 1rem 0;
  }
  p {
    color: #fff;
    font-size: 0.9375rem;
    line-height: 1.5;
    margin: 0;
  }
  &.colored-background {
    .content {
      justify-content: center;
      align-items: center;
      text-align: center;
      &::after {
        content: '';
        height: unset;
        width: unset;
      }
      .read-more {
        color: #fff;
        font-weight: 700;
        font-size: 0.9375rem;
        font-family: $inter;
        display: inline-block;
        margin-top: 1.75rem;
      }
    }
  }
  @media (min-width: 1300px) {
    &.span-2-cols {
      .content div {
        max-width: 70%;
      }
    }
  }
}
</style>
