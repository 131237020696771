<script lang="ts">
import { defineComponent } from 'vue'
import _ from 'lodash'
import ProductCard from './ProductCard.vue'
import FilterProducts from './FilterProducts.vue'
import TipsAndAdviceBox from './TipsAndAdviceBox.vue'
import CampaignCard from './CampaignCard.vue'
import { storeToRefs } from 'pinia'
import { useStore } from '../store'
import { isClient } from '@/utils/helpers'

export default defineComponent({
  components: {
    ProductCard,
    FilterProducts,
    TipsAndAdviceBox,
    CampaignCard
  },
  props: {
    queryId: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      required: true
    },
    showFilter: {
      type: Boolean,
      default: true
    },
    isLoadingMore: {
      type: Boolean,
      default: false
    },
    loadMoreCount: {
      type: Number,
      default: 0
    }
  },
  emits: ['loadMore'],
  setup() {
    const store = useStore()
    const { setLoadingState, fetchProducts } = useStore()
    const { productsQueryResult, productsQuery, loadingState } = storeToRefs(store)
    return { setLoadingState, fetchProducts, loadingState, productsQuery, productsQueryResult }
  },
  data() {
    return {
      refreshResults: _.debounce(() => {
        this.refreshResultsNow()
      }, 300),
      filterPosition: true,
      filterScrollUp: false,
      lastScrollPosition: 0,
      exampleTipsAndAdvice: [
        // Example content for the "Tips & råd" boxes
        {
          id: 'item-870c6c68-f577-4358-a7df-b7859ba2d86f',
          name: 'Hippo nyttjar råvarornas fördelar',
          description:
            'Hästen är av naturen en gräsätare. Många moderna hästar förväntas dock prestera på en nivå där det är nästintill omöjligt att näringsförsörja dem på bara gräs.',
          image:
            'https://www.svenskafoder.se/wp-content/uploads/2021/02/Ungh%C3%A4stverkstaden.jpg',
          color: '',
          col: 4,
          row: 5,
          span: 1
        },
        {
          id: '5d6974cf-9844-41ac-8543-93fc7a639698',
          name: 'Hippo nyttjar råvarornas fördelar',
          description:
            'Hästen är av naturen en gräsätare. Många moderna hästar förväntas dock prestera på en nivå där det är nästintill omöjligt att näringsförsörja dem på bara gräs.',
          image: '',
          color: '#8A887B',
          col: 3,
          row: 10,
          span: 2
        },
        {
          id: '6e856e85-16bf-48d6-b1b1-5761b2ee8ad4',
          name: 'Hippo nyttjar råvarornas fördelar',
          description:
            'Hästen är av naturen en gräsätare. Många moderna hästar förväntas dock prestera på en nivå där det är nästintill omöjligt att näringsförsörja dem på bara gräs.',
          image:
            'https://www.svenskafoder.se/wp-content/uploads/2021/02/Ungh%C3%A4stverkstaden.jpg',
          color: '',
          col: 1,
          row: 15,
          span: 2
        },
        {
          id: '92128495-e6fa-45a7-9973-f075c7f7bdd6',
          name: 'Hippo nyttjar råvarornas fördelar',
          description:
            'Hästen är av naturen en gräsätare. Många moderna hästar förväntas dock prestera på en nivå där det är nästintill omöjligt att näringsförsörja dem på bara gräs.',
          image:
            'https://www.svenskafoder.se/wp-content/uploads/2021/02/Ungh%C3%A4stverkstaden.jpg',
          color: '',
          col: 1,
          row: 20,
          span: 1
        }
      ]
    }
  },
  computed: {
    results() {
      return this.productsQueryResult[this.queryId]
    },
    lastQuery() {
      return this.productsQuery[this.queryId]
    },
    products() {
      return this.results.hits
    },
    tipsAndAdvice() {
      return this.results.tips_and_advice
    }
  },
  // Client-side only
  watch: {
    query: {
      handler() {
        if (!isClient) {
          return
        }
        if (
          this.results !== null &&
          _.isEqual(
            Object.assign({}, this.lastQuery, { offset: 0 }),
            Object.assign({}, this.query, { offset: 0 })
          )
        ) {
          console.log('probably already ssr fetched ;)')
          this.setLoadingState(false)
          return
        }
        console.log('QUERY CHANGED, FORCE FETCHING NEW')
        if (this.$route.query.page && parseInt(this.$route.query.page as string) > 0) {
          console.log('query changed')
          let newQuery = Object.assign({}, this.$route.query)
          if (newQuery.page) {
            delete newQuery.page
            this.$router.push({ query: newQuery })
          }
        }
        this.refreshResults()
      },
      immediate: true
    }
  },
  mounted() {
    this.lastScrollPosition = window.scrollY
    window.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    refreshResultsNow() {
      return this.fetchProducts({
        id: this.queryId,
        query: this.query
      })
    },
    loadMore() {
      this.$emit('loadMore')
    },
    onScroll() {
      var loadMoreRef = this.$refs['load-more-box'] as HTMLElement
      if (
        loadMoreRef &&
        this.loadMoreCount <= 2 &&
        loadMoreRef?.getBoundingClientRect().top - window.innerHeight < -50
      ) {
        this.loadMore()
      }

      const OFFSET = 190
      const PARTIAL_OFFSET = 190

      if (window.scrollY < 0) {
        return
      }
      if (Math.abs(window.scrollY - this.lastScrollPosition) < OFFSET) {
        return
      }
      if (window.scrollY < PARTIAL_OFFSET) {
        this.filterPosition = window.scrollY < this.lastScrollPosition
        this.filterScrollUp = false
      } else {
        this.filterPosition = window.scrollY < this.lastScrollPosition
        this.filterScrollUp = true
      }
      this.lastScrollPosition = window.scrollY
    }
  }
})
</script>

<template>
  <div v-if="results">
    <div class="grid-category">
      <div
        v-if="showFilter"
        class="filter-container"
        :class="{
          'filter-fixed': !filterPosition,
          'scroll-up-fixed': filterScrollUp
        }"
      >
        <FilterProducts class="grid-item filter" :buckets="results.buckets" />
      </div>
      <template v-if="products">
        <ProductCard
          v-for="(product, index) in products"
          :key="index"
          :product="product"
          :loading="loadingState"
        />
      </template>
      <template v-if="isLoadingMore">
        <ProductCard
          v-for="i in productsQuery[queryId].limit"
          :key="`fake_${i}`"
          :product="products[0]"
          :loading="true"
        />
      </template>
      <div v-show="products.length === 0">
        <p class="no-products">Inga produkter hittades...</p>
      </div>
      <template v-for="(item, index) in tipsAndAdvice">
        <TipsAndAdviceBox
          v-if="item.type == 'tips-and-advice' && (index + 1) * 16 < products.length"
          :key="item.id + '-' + index"
          v-bind="item"
          :index="index"
        />
        <CampaignCard
          v-if="item.type == 'campaign' && (index + 1) * 16 < products.length"
          :key="item.id + '-' + index"
          v-bind="item"
          :index="index"
        />
      </template>
    </div>

    <div
      v-if="products.length < productsQueryResult[queryId].total"
      ref="load-more-box"
      class="load-more"
    >
      <button class="load-btn" @click="loadMore()">
        <span class="show-more">Visa fler produkter</span>
        <div class="progress">
          <div
            class="value"
            :style="{
              width: (products.length / productsQueryResult[queryId].total) * 100 + '%'
            }"
          ></div>
        </div>
        <span class="total-shown">
          {{ products.length }} av {{ productsQueryResult[queryId].total }} produkter
        </span>
      </button>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/style.scss';

.filter-fixed {
  @media (max-width: 599px) {
    transform: translateY(calc(-64px));
  }
}
.scroll-up-fixed {
  @media (max-width: 599px) {
    transform: translateY(calc(-64px));
  }
}

.grid-category {
  border-top: 1px solid $highlight;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);
  }
  .filter-container {
    grid-column: span 2;
    grid-row: span 2;
    border-right: 1px solid $highlight;
    border-bottom: 1px solid $highlight;
    position: fixed;
    top: 12em;
    z-index: 30;
    background: $white;
    width: 100%;
    transition: 0.3s all ease-out;
    box-shadow: 0 7px 20px -7px rgba(0, 0, 0, 0.3);
    max-height: 60vh;
    overflow: auto;

    @media (min-width: 600px) {
      grid-column: span 1;
      position: relative;
      z-index: unset;
      background: transparent;
      width: unset;
      box-shadow: unset;
      top: unset;
      max-height: unset;
      overflow: unset;
    }
  }
  .filter {
    padding: 4em;
    max-height: 1200px;
    overflow-y: auto;

    @media (max-width: 1550px) {
      padding: 1.8em;
    }
  }
}
.product {
  background-color: transparent;
  transition: 0.3s ease-out background-color;
  will-change: background-color;
  &.loading {
    transition: all 0.5s ease-in;

    .grid-item-img {
      filter: blur(10px);
      transform: scale(0.95);
    }
  }
}
.no-products {
  padding: 1.5em;
  font-size: 1.4em;
  font-family: $inter;
  font-weight: 600;
  letter-spacing: -0.17px;
}
.load-more {
  display: flex;
  justify-content: center;
  margin: 3rem 1rem 1rem;
  .load-btn {
    border: none;
    background: transparent;
    outline: none;
    &:hover {
      .show-more {
        &::after {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    }
    .show-more {
      font-weight: 600;
      color: $main;
      font-family: $inter;
      padding: 1rem 0 2rem;
      letter-spacing: -0.2px;
      font-size: 0.9375rem;
    }
    .progress {
      width: 200px;
      height: 5px;
      background: $highlight;
      border-radius: 333px;
      position: relative;
      margin: 2.5rem 0 0.7rem;
      .value {
        height: 100%;
        position: absolute;
        background: $main;
        left: 0;
        border-radius: 333px;
      }
    }
    .total-shown {
      color: $border;
      font-size: 0.6875rem;
    }
  }
}

// Very rough base for the "Tips & råd" boxes
.tips-and-advice-box {
  position: relative;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  @media (max-width: 1299px) {
    grid-column: span 1 !important;
  }
  @media (max-width: 1023px) {
    grid-column: span 2 !important;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left center;
    display: block;
  }
  .content {
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  h2 {
    color: #fff;
    font-family: $brandon;
    font-weight: 700;
    font-size: 2.5rem;
    margin: 0 0 1rem 0;
  }
  p {
    color: #fff;
    font-size: 0.9375rem;
    margin: 0;
  }
  &.colored-background {
    .content {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
  @media (min-width: 1300px) {
    &.span-2-cols {
      .content div {
        max-width: 70%;
      }
    }
  }
}
</style>
